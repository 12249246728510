import Image from 'next/image';

export default function FlightTabAirNameLogo({ allData, route, hide }: any) {
  const airWaysNameAndLogo = allData.airline[route?.companyId];
  return (
    <div className="mt-2 flex w-full items-center gap-4 lg:mt-0 2xl:w-48">
      <Image
        src={
          airWaysNameAndLogo?.logo?.includes('https')
            ? airWaysNameAndLogo?.logo
            : `https://cdn.ticketmet.com/aircontrol/${airWaysNameAndLogo?.logo}`
        }
        alt=""
        width={160}
        height={160}
        className="size-6 rounded-lg bg-slate-100 object-contain lg:size-8"
      />
      <div>
        <h4 className="text-xs font-semibold text-gray-600 lg:text-sm">
          {airWaysNameAndLogo?.name?.length > 10
            ? airWaysNameAndLogo?.name?.slice(0, 10) + '...'
            : airWaysNameAndLogo?.name?.length < 10
            ? airWaysNameAndLogo?.name
            : 'Not Found'}
        </h4>
        <div className="text-[9px] font-medium text-gray-500 lg:text-[11px]">
          <h3>
            {route?.companyId}
            {route?.flightNumber}
            {!hide && route?.equipmentType && (
              <>
                {' - '} {route?.equipmentType}
              </>
            )}
          </h3>
          {!hide && (
            <h3>
              Seat: {route?.rbd}-{route?.avlStatus}
            </h3>
          )}
        </div>
      </div>
    </div>
  );
}
