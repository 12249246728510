import React, { useEffect, useRef } from 'react';
import { useAccordionContext } from './CustomAccordion';

interface AccordionContentProps {
  children: React.ReactNode;
  className?: string;
}

const CustomAccordionContent: React.FC<AccordionContentProps> = ({
  children,
  className,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);
  const { isOpen } = useAccordionContext();
  const COLLAPSE_HEIGHT = '0px'; // Constant for collapsed height

  // Update max height based on content's size
  const updateMaxHeight = () => {
    if (contentRef.current) {
      contentRef.current.style.maxHeight = isOpen
        ? `${contentRef.current.scrollHeight}px`
        : COLLAPSE_HEIGHT;
    }
  };

  // Watch for `isOpen` and `children` changes to update maxHeight
  useEffect(() => {
    updateMaxHeight();
  }, [isOpen, children]);

  // Observe dynamic content changes to recalculate height
  useEffect(() => {
    const observer = new MutationObserver(updateMaxHeight);

    if (contentRef.current) {
      observer.observe(contentRef.current, {
        childList: true,
        subtree: true,
        characterData: true,
      });
    }

    return () => {
      observer.disconnect();
    };
  }, [children]);

  // Recalculate height on window resize
  useEffect(() => {
    if (isOpen) {
      window.addEventListener('resize', updateMaxHeight);
      return () => {
        window.removeEventListener('resize', updateMaxHeight);
      };
    }
  }, [isOpen]);

  return (
    <div
      ref={contentRef}
      className="max-h-auto h-fit transition-[max-height] duration-400 ease-soft-spring"
    >
      <div className={className}>{children}</div>
    </div>
  );
};

export default CustomAccordionContent;
