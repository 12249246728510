import React, { createContext, ReactNode, useContext } from 'react';
import { cn } from '@/utils/utils';

interface AccordionContextProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const AccordionContext = createContext<AccordionContextProps | undefined>(
  undefined,
);

interface AccordionProps {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

const CustomAccordion: React.FC<AccordionProps> = ({
  children,
  className,
  isOpen,
  setIsOpen,
}) => {
  return (
    <AccordionContext.Provider value={{ isOpen, setIsOpen }}>
      <div className={cn('overflow-hidden rounded-lg bg-white', className)}>
        {children}
      </div>
    </AccordionContext.Provider>
  );
};

export const useAccordionContext = () => {
  const context = useContext(AccordionContext);
  if (!context) {
    throw new Error('useAccordionContext must be used within an Accordion');
  }
  return context;
};

export default CustomAccordion;
