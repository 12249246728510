import dayjs from 'dayjs';
// import { formatTime } from "@/lib/formateDate"

export default function FlightTabDateDetails({ flight, airports }: any) {
  function formatDate(dateString) {
    // Check if the input already has seconds
    let datecheck = dateString;

    if (datecheck.length === 16) {
      // If it doesn't have seconds, add ":00"
      datecheck += ':00Z';
    }

    // Parse the input date string
    const date = new Date(datecheck);

    // Format the date to the desired output
    const newDate = date.toISOString().slice(0, 19);
    return newDate;
  }

  const flightDeparture = flight?.departure
    ? formatDate(flight?.departure)
    : null;
  const flightArrival = flight?.arrival ? formatDate(flight?.arrival) : null;

  const airportNames = airports;

  const getAirportName = (code: any) => {
    const airport = airportNames[code];
    return airport?.name || '';
  };

  const airOrigin = airportNames[flight?.origin];
  const airDes = airportNames[flight?.destination];

  return (
    <div className="flex w-full items-center justify-between lg:w-full">
      <div className="lg:w-96">
        <h3 className="text-md time-of-departure font-semibold md:text-lg">
          {dayjs(flightDeparture).format('HH:mm')}
        </h3>
        <h4 className="date-of-departure text-xs md:text-sm">
          {dayjs(flightDeparture).format('ddd, DD MMM, YY')}
        </h4>

        <h4 className="flex text-xs md:text-sm">
          <span>{airOrigin?.city}</span>
          <span className="hidden lg:block">
            &nbsp;|&nbsp;{flight?.origin}&nbsp;-&nbsp;
          </span>
          <span className="hidden lg:block">
            {getAirportName(flight?.origin || '')}
          </span>
        </h4>
      </div>

      <h4 className="text-xs text-blue-600 lg:text-base">
        {/* {calculateDuration(
          flightDeparture || "",
          flightArrival || ""
        )} */}
        {flight?.flightTime}
      </h4>

      <div className="text-end lg:w-96">
        <h3 className="text-md time-of-arrival font-semibold md:text-lg">
          {dayjs(flightArrival).format('HH:mm')}
        </h3>
        <h4 className="date-of-arrival text-xs md:text-sm">
          {dayjs(flightArrival).format('ddd, DD MMM, YY')}
        </h4>

        <h4 className="flex justify-end text-xs md:text-sm">
          <span>{airDes?.city}</span>
          <span className="hidden lg:block">
            &nbsp;|&nbsp;{flight?.destination}&nbsp;-&nbsp;
          </span>
          <span className="hidden lg:block">
            {getAirportName(flight?.destination || '')}
          </span>
        </h4>
      </div>
    </div>
  );
}
