import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';
import { useAppSelector } from '@/store/hooks';

export default function FareInfoTab({ item }: any) {
  const userRole = useAppSelector((state) => state.userData?.user?.role);

  const adultTotal =
    (item?.pricing?.paxInfo?.adult?.fare -
      item?.pricing?.paxInfo?.adult?.discount) *
    item?.pricing?.paxInfo?.adult?.paxCount;
  const childTotal =
    (item?.pricing?.paxInfo?.child?.fare -
      item?.pricing?.paxInfo?.child?.discount) *
    item?.pricing?.paxInfo?.child?.paxCount;
  const infantTotal =
    (item?.pricing?.paxInfo?.infant?.fare -
      item?.pricing?.paxInfo?.infant?.discount) *
    item?.pricing?.paxInfo?.infant?.paxCount;

  return (
    <div className="overflow-hidden rounded-lg border">
      <Table>
        <TableHeader className="bg-slate-100">
          <TableRow>
            <TableHead>PaxType</TableHead>
            <TableHead>Base Fare</TableHead>
            <TableHead>Tax</TableHead>
            {(userRole === 'B2B' ||
              userRole === 'SUDO' ||
              userRole === 'MOD' ||
              userRole === 'ADMIN') && <TableHead>Without Per Pax</TableHead>}
            <TableHead>Discount</TableHead>
            <TableHead>Per Pax</TableHead>
            <TableHead className="text-right">AIT</TableHead>
            <TableHead className="text-right">Total Pax</TableHead>
            <TableHead className="text-right">Total</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {item?.pricing?.paxInfo?.adult?.baseFare && (
            <TableRow>
              <TableCell>Adult</TableCell>
              <TableCell>
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.adult?.baseFare,
                )}
              </TableCell>
              <TableCell>
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.adult?.tax,
                )}
              </TableCell>

              {(userRole === 'B2B' ||
                userRole === 'SUDO' ||
                userRole === 'MOD' ||
                userRole === 'ADMIN') && (
                <TableCell>
                  {new Intl.NumberFormat('en-IN').format(
                    item?.pricing?.paxInfo?.adult?.baseFare +
                      item?.pricing?.paxInfo?.adult?.tax || 0,
                  )}
                </TableCell>
              )}
              <TableCell>
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.adult?.discount,
                )}
              </TableCell>
              <TableCell>
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.adult?.fare -
                    item?.pricing?.paxInfo?.adult?.discount,
                )}
              </TableCell>
              <TableCell className="text-right">
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.adult?.ait,
                )}
              </TableCell>
              <TableCell className="text-right">
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.adult?.paxCount,
                )}
              </TableCell>
              <TableCell className="text-right">
                {new Intl.NumberFormat('en-IN').format(adultTotal)}
              </TableCell>
            </TableRow>
          )}

          {item?.pricing?.paxInfo?.child?.baseFare && (
            <TableRow>
              <TableCell>Child</TableCell>
              <TableCell>
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.child?.baseFare,
                )}
              </TableCell>
              <TableCell>
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.child?.tax,
                )}
              </TableCell>

              {(userRole === 'B2B' ||
                userRole === 'SUDO' ||
                userRole === 'MOD' ||
                userRole === 'ADMIN') && (
                <TableCell>
                  {new Intl.NumberFormat('en-IN').format(
                    item?.pricing?.paxInfo?.child?.baseFare +
                      item?.pricing?.paxInfo?.child?.tax || 0,
                  )}
                </TableCell>
              )}
              <TableCell>
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.child?.discount,
                )}
              </TableCell>
              <TableCell>
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.child?.fare -
                    item?.pricing?.paxInfo?.child?.discount,
                )}
              </TableCell>
              <TableCell className="text-right">
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.child?.ait,
                )}
              </TableCell>
              <TableCell className="text-right">
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.child?.paxCount,
                )}
              </TableCell>
              <TableCell className="text-right">
                {new Intl.NumberFormat('en-IN').format(childTotal)}
              </TableCell>
            </TableRow>
          )}

          {item?.pricing?.paxInfo?.infant?.baseFare && (
            <TableRow>
              <TableCell>Infant</TableCell>
              <TableCell>
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.infant?.baseFare,
                )}
              </TableCell>
              <TableCell>
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.infant?.tax,
                )}
              </TableCell>

              {(userRole === 'B2B' ||
                userRole === 'SUDO' ||
                userRole === 'MOD' ||
                userRole === 'ADMIN') && (
                <TableCell>
                  {new Intl.NumberFormat('en-IN').format(
                    item?.pricing?.paxInfo?.infant?.baseFare +
                      item?.pricing?.paxInfo?.infant?.tax || 0,
                  )}
                </TableCell>
              )}
              <TableCell>
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.infant?.discount,
                )}
              </TableCell>
              <TableCell>
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.infant?.fare -
                    item?.pricing?.paxInfo?.infant?.discount,
                )}
              </TableCell>
              <TableCell className="text-right">
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.infant?.ait,
                )}
              </TableCell>
              <TableCell className="text-right">
                {new Intl.NumberFormat('en-IN').format(
                  item?.pricing?.paxInfo?.infant?.paxCount,
                )}
              </TableCell>
              <TableCell className="text-right">
                {new Intl.NumberFormat('en-IN').format(infantTotal)}
              </TableCell>
            </TableRow>
          )}

          {item?.pricing?.totalPayable ? (
            <>
              <TableRow>
                <TableCell>Sub Total</TableCell>
                <TableCell>
                  {new Intl.NumberFormat('en-IN').format(
                    item?.pricing?.totalBaseFare || 0,
                  )}
                </TableCell>
                <TableCell>
                  {new Intl.NumberFormat('en-IN').format(
                    item?.pricing?.totalTax || 0,
                  )}
                </TableCell>
                {(userRole === 'B2B' ||
                  userRole === 'SUDO' ||
                  userRole === 'MOD' ||
                  userRole === 'ADMIN') && (
                  <TableCell>
                    {new Intl.NumberFormat('en-IN').format(
                      item?.pricing?.totalBaseFare + item?.pricing?.totalTax ||
                        0,
                    )}
                  </TableCell>
                )}
                <TableCell>
                  {new Intl.NumberFormat('en-IN').format(
                    item?.pricing?.totalDiscount || 0,
                  )}
                </TableCell>
                <TableCell></TableCell>
                <TableCell className="text-right">
                  {new Intl.NumberFormat('en-IN').format(
                    item?.pricing?.totalAit,
                  )}
                </TableCell>

                <TableCell></TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  colSpan={
                    userRole === 'B2B' ||
                    userRole === 'SUDO' ||
                    userRole === 'MOD' ||
                    userRole === 'ADMIN'
                      ? 8
                      : 7
                  }
                  align="right"
                  className="font-semibold"
                >
                  Grand Total:
                </TableCell>

                <TableCell className="text-right font-semibold">
                  {new Intl.NumberFormat('en-IN').format(
                    item?.pricing?.totalPayable,
                  )}
                </TableCell>
              </TableRow>
            </>
          ) : (
            <>
              <TableRow>
                <TableCell
                  colSpan={
                    userRole === 'B2B' ||
                    userRole === 'SUDO' ||
                    userRole === 'MOD' ||
                    userRole === 'ADMIN'
                      ? 8
                      : 7
                  }
                  align="right"
                >
                  AIT:
                </TableCell>

                <TableCell className="text-right">
                  {new Intl.NumberFormat('en-IN').format(
                    item?.pricing?.totalAit,
                  )}
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell
                  colSpan={
                    userRole === 'B2B' ||
                    userRole === 'SUDO' ||
                    userRole === 'MOD' ||
                    userRole === 'ADMIN'
                      ? 8
                      : 7
                  }
                  align="right"
                  className="font-semibold"
                >
                  Grand Total:
                </TableCell>

                <TableCell className="text-right font-semibold">
                  ৳{' '}
                  {new Intl.NumberFormat('en-IN').format(
                    item?.pricing?.totalPayable,
                  )}
                </TableCell>
              </TableRow>
            </>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
