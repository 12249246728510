import { calculateDuration } from '@/utils/calculateDuration';
import FlightTabAirNameLogo from '../searchListsTabs/FlightTabAirNameLogo';
import FlightTabDateDetails from '../searchListsTabs/FlightTabDateDetails';
import AirlinesLogoName from '../searchListsItem/AirlinesLogoName';
import OriginDepartureInfo from '../searchListsItem/OriginDepartureInfo';
import TransitInfo from '../searchListsItem/TransitInfo';
import DestinationArrivalInfo from '../searchListsItem/DestinationArrivalInfo';

export default function FlightTab({ allData, item, airWaysName, hide }: any) {
  const airportNames = allData.airport;

  return (
    <div className="flex flex-col gap-2">
      {/* For Oneway Search Results */}
      {item?.routes?.length >= 1 && (
        <div>
          {item?.routes[0]?.FlightDetails.length > 0 && (
            <div className="flex flex-col items-center justify-between gap-2 pb-2 lg:gap-4 2xl:flex-row">
              <FlightTabAirNameLogo
                allData={allData}
                route={item?.routes[0]?.FlightDetails[0]}
                airWaysName={airWaysName}
                hide={hide}
              />

              <FlightTabDateDetails
                flight={item?.routes[0]?.FlightDetails[0]}
                airports={airportNames}
              />
            </div>
          )}

          {item?.routes[0]?.FlightDetails.length > 1 && (
            <div className="relative flex flex-col items-center justify-between gap-2 border-t py-2 lg:gap-4 2xl:flex-row">
              <FlightTabAirNameLogo
                allData={allData}
                route={item?.routes[0]?.FlightDetails[1]}
                airWaysName={airWaysName}
                hide={hide}
              />

              <FlightTabDateDetails
                flight={item?.routes[0]?.FlightDetails[1]}
                airports={airportNames}
              />

              <h4 className="absolute -top-2 right-1/3 rounded bg-blue-600 px-4 text-xs text-white lg:-top-3 lg:text-sm">
                <span>Layover:&nbsp;</span>
                <span>
                  {calculateDuration(
                    item?.routes[0]?.FlightDetails?.[0]?.arrival,
                    item?.routes[0]?.FlightDetails?.[1]?.departure,
                  )}
                </span>
              </h4>
            </div>
          )}

          {item?.routes[0]?.FlightDetails.length > 2 && (
            <div className="relative flex flex-col items-center justify-between gap-2 border-t pt-2 lg:gap-4 2xl:flex-row">
              <FlightTabAirNameLogo
                allData={allData}
                route={item?.routes[0]?.FlightDetails[2]}
                airWaysName={airWaysName}
                hide={hide}
              />

              <FlightTabDateDetails
                flight={item?.routes[0]?.FlightDetails[2]}
                airports={airportNames}
              />

              <h4 className="absolute -top-2 right-1/3 rounded bg-blue-600 px-4 text-xs text-white lg:-top-3 lg:text-sm">
                <span>Layover:&nbsp;</span>
                <span>
                  {calculateDuration(
                    item?.routes[0]?.FlightDetails?.[1]?.arrival,
                    item?.routes[0]?.FlightDetails?.[2]?.departure,
                  )}
                </span>
              </h4>
            </div>
          )}

          {item?.routes[0]?.FlightDetails.length > 3 && (
            <div className="relative flex flex-col items-center justify-between gap-2 border-t pt-2 lg:gap-4 2xl:flex-row">
              <FlightTabAirNameLogo
                allData={allData}
                route={item?.routes[0]?.FlightDetails[3]}
                airWaysName={airWaysName}
                hide={hide}
              />

              <FlightTabDateDetails
                flight={item?.routes[0]?.FlightDetails[3]}
                airports={airportNames}
              />

              <h4 className="absolute -top-2 right-1/3 rounded bg-blue-600 px-4 text-xs text-white lg:-top-3 lg:text-sm">
                <span>Layover:&nbsp;</span>
                <span>
                  {calculateDuration(
                    item?.routes[0]?.FlightDetails?.[2]?.arrival,
                    item?.routes[0]?.FlightDetails?.[3]?.departure,
                  )}
                </span>
              </h4>
            </div>
          )}
        </div>
      )}

      {/* For Return Search Results */}
      {item.routes.length == 2 && (
        <div>
          <p className="mb-2 rounded-md bg-slate-200 text-center text-sm capitalize">
            Return
          </p>

          {item.routes[1].FlightDetails.map((flight, index) => (
            <div
              key={index}
              className={`flex flex-col items-center justify-between gap-4 ${
                index > 0 ? 'border-t pt-2' : 'pb-2'
              } 2xl:flex-row ${index > 0 ? 'relative' : ''}`}
            >
              <FlightTabAirNameLogo
                allData={allData}
                route={flight}
                airWaysName={airWaysName}
                hide={hide}
              />

              <FlightTabDateDetails flight={flight} airports={airportNames} />

              {index > 0 && (
                <h4 className="absolute -top-2 right-1/3 rounded bg-blue-600 px-4 text-xs text-white lg:-top-3 lg:text-sm">
                  <span>Layover:&nbsp;</span>
                  <span>
                    {calculateDuration(
                      item.routes[1].FlightDetails[index - 1].arrival,
                      flight.departure,
                    )}
                  </span>
                </h4>
              )}
            </div>
          ))}
        </div>
      )}

      {/* For Multicity Search Results */}
      {item.routes.length > 2 &&
        item.routes.slice(1).map((route: any, index: any) => (
          <div
            key={index}
            className="relative mt-2 flex flex-col items-center gap-2 border-t pt-2 2xl:flex-row"
          >
            <AirlinesLogoName
              allData={allData}
              item={item}
              route={route}
              width="w-full mt-2 md:mt-0 2xl:w-48"
            />

            <div className="item-center flex w-full justify-between gap-4">
              <OriginDepartureInfo allData={item} route={route} split={false} />
              <TransitInfo route={route} split={true} />
              <DestinationArrivalInfo
                allData={item}
                route={route}
                split={false}
              />
            </div>

            <h4 className="absolute -top-2 right-1/3 rounded bg-blue-600 px-4 text-xs text-white lg:-top-3 lg:text-sm">
              <span>Layover:&nbsp;</span>
              <span>
                {calculateDuration(
                  item?.routes[index]?.FlightDetails?.[0]?.arrival,
                  item?.routes[index]?.FlightDetails?.[0]?.departure,
                )}
              </span>
            </h4>
          </div>
        ))}
    </div>
  );
}
