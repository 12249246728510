import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@/components/ui/table';

export default function BaggageTab({ item }: any) {
  return (
    <div className="mt-4 overflow-hidden rounded-lg border">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>Passenger</TableHead>
            <TableHead>Check In</TableHead>
            <TableHead>Hand Bag</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {item.routes.map((route: any, index: number) => (
            <>
              <TableRow key={index}>
                <TableCell>Adult</TableCell>
                <TableCell>{route.adultBaggage}</TableCell>
                <TableCell>{route.handBaggage}</TableCell>
              </TableRow>
              {route.childBaggage && (
                <TableRow key={index + 10}>
                  <TableCell>Child</TableCell>
                  <TableCell>{route.childBaggage}</TableCell>
                  <TableCell>{route.handBaggage}</TableCell>
                </TableRow>
              )}
              {route.infantBaggage && (
                <TableRow key={index + 20}>
                  <TableCell>Infant</TableCell>
                  <TableCell>{route.infantBaggage}</TableCell>
                  <TableCell>{route.handBaggage}</TableCell>
                </TableRow>
              )}
            </>
          ))}
        </TableBody>
      </Table>
    </div>
  );
}
