import React from 'react';
import { MdKeyboardArrowDown } from '@/Icons/md';
import { cn } from '@/utils/utils';
import { useAccordionContext } from './CustomAccordion';

interface AccordionTriggerProps {
  children: React.ReactNode;
  className?: string;
}

const CustomAccordionTrigger: React.FC<AccordionTriggerProps> = ({
  children,
  className,
}) => {
  const { isOpen, setIsOpen } = useAccordionContext();

  return (
    <button
      onClick={() => setIsOpen(!isOpen)}
      className={cn(
        `${
          isOpen ? 'border-b' : 'border-b-0'
        } flex w-full select-none items-center justify-between px-4 py-[10px] text-sm font-semibold transition-all duration-300 ease-soft-spring lg:text-[15px]`,
        className,
      )}
    >
      {children}
      {isOpen ? (
        <MdKeyboardArrowDown className="rotate-0 text-lg transition-all duration-300 ease-soft-spring" />
      ) : (
        <MdKeyboardArrowDown className="rotate-90 text-lg transition-all duration-300 ease-soft-spring" />
      )}
    </button>
  );
};

export default CustomAccordionTrigger;
